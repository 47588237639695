html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
    box-sizing: border-box;
}

.error-container {
    color: #9c0006;
    border-radius: 5px;
    padding: 2px;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
}

.error-msg {
    color: #9c0006;
    border-radius: 10px;
    padding: 10px;
    background-color: #ffc7ce;
    border: 2px solid red;
}

.left-text {
    text-align: left;
    direction: ltr;
}

.mat-select-disabled .mat-select-value {
    color: black;
}

.mat-slide-toggle.mat-disabled {
    opacity: 1 !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.04);
}

.mat-sort-header-container {
    font-size: medium;
    display: flex;
    cursor: pointer;
    align-items: center;
    letter-spacing: normal;
    outline: 0;
    justify-content: center;
    align-content: center;
    flex-direction: row;
}
.mat-cell {
    text-align: center;
}
.mat-sort-header-arrow {
    color: skyblue;
}

input[type="number"],
input[type="email"] {
    direction: ltr;
    text-align: left;
}
